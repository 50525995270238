<template>
  <section class="checkPeopleJury-index" v-auth="'statusUpdate,peopleUpdata,peopleDetail'">
    <edit v-show="componentName==='edit'" @changeComponent="changeComponent" ref="edit" :dic="dic"></edit>
    <index v-show="componentName==='index'" @changeComponent="changeComponent" ref="index" :dic="dic"></index>
  </section>
</template>

<script>
import edit from './components/edit';
import index from './components/index';

export default {
  name: 'peopleJury',
  props: {},
  components: {
    index,
    edit,
  },
  data() {
    return {
      componentName: '',
      dic: {},
    };
  },
  methods: {
    changeComponent(name, data) {
      this.componentName = name;
      if (name === 'edit') {
        this.$nextTick(() => {
          this.$refs.edit.toggle(data, 'edit');
        });
        return;
      }
    },
  },
  mounted() {},
  created() {
    let { mz, xb, zzmm, whcd, bmfs, grzdsxbh, zylb, zwjb, rddb, sfflgzz, sfzbry, sflyys, dwxz, zxwy, sfxbwy, sfcwhgzry, sczt } =
      this.$store.getters.dic;
    this.dic = {
      mz,
      xb,
      zzmm,
      whcd,
      bmfs,
      grzdsxbh,
      zylb,
      zwjb,
      rddb,
      sfflgzz,
      sfzbry,
      sflyys,
      dwxz,
      zxwy,
      sfxbwy,
      sfcwhgzry,
      sczt,
    };
    this.changeComponent('index');
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.checkPeopleJury-index {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}
</style>
